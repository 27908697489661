import "floating-vue/dist/style.css";
import "./main.scss";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { createHead } from "@unhead/vue";
import FloatingVue from "floating-vue";
import i18n from './app/i18n/i18n';
import * as Sentry from "@sentry/browser";

import.meta.glob(["./img/**"]);

const { t } = i18n.global;

const locale = currentLocale()

const pinia = createPinia();
Sentry.init({
	dsn: "https://e8507ac15b79497b9e48c952a0c81b24@sentry.raketa.agency/21",
	integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
	tracesSampleRate: 1.0,
	tracePropagationTargets: [
		"localhost",
		/^https:\/\/stage\.myscore\.club\//,
		/^https:\/\/stage\.myscore\.club\/api\//,
	],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
Sentry.configureScope((scope) => {
	scope.setTag("frontend", "ts/vue");
});
// PopUp
import PopUp from "./app/components/pop-up/PopUp.vue";
const popUpEl = document.querySelector("#pop-up");
if (popUpEl) {
	createApp(PopUp)
		.use(pinia)
		.use(i18n)
		.use(FloatingVue, {
			disposeTimeout: 0,
			themes: {
				"history-tooltip": {
					$extend: "tooltip",
				},
			},
		})
		.mount(popUpEl);
}

// gameHeadEvents
import gameHeadEvents from "./app/modules/game/components/game-head/GameHeadEvents.vue";
const gameHeadEventsEl = document.querySelector("#game-head-events");
if (gameHeadEventsEl) {
    const sportType = gameHeadEventsEl.getAttribute('sportType');
	
    createApp(gameHeadEvents, {
		
        sportType: sportType
    }).mount(gameHeadEventsEl);
}

// Favorites
import Favorites from "./app/modules/favorites/Favorites.vue";
// --League
import LeagueFavorites from "./app/modules/favorites/components/FavoritesLeague.vue";
const favoritesLeagueEl: HTMLElement | null = document.querySelector("#favorites_leagues");
let startLeague: any, sportType: string | undefined;
if (favoritesLeagueEl) {
	startLeague = favoritesLeagueEl.querySelector("script[type='application/json']");
	startLeague = JSON.parse(startLeague.textContent);
	sportType = favoritesLeagueEl.dataset.sportType;

	createApp(Favorites)
		.provide("sportType", sportType)
		.provide("favoriteName", t('header.ligue') )
		.provide("component", LeagueFavorites)
		.provide("startLeague", startLeague)
		.use(pinia)
		.use(i18n)
		.mount(favoritesLeagueEl);
}
// --Teams
import TeamsFavorites from "./app/modules/favorites/components/FavoritesTeams.vue";
const favoritesTeamsEl: HTMLElement | null = document.querySelector("#favorites_teams");
if (favoritesTeamsEl) {
	// startTeam = favoritesTeamsEl.querySelector("script[type='application/json']");
	// startTeam = JSON.parse(startTeam.textContent);
	sportType = favoritesTeamsEl.dataset.sportType;

	createApp(Favorites)
		.provide("sportType", sportType)
		.provide("favoriteName", t('header.team'))
		.provide("component", TeamsFavorites)
		// .provide("startTeam", startTeam)
		.use(pinia)
		.use(i18n)
		.mount(favoritesTeamsEl);
}
// --Country
import CountryFavorites from "./app/modules/favorites/components/FavoritesCountry.vue";
const favoritesCountryEl: HTMLElement | null = document.querySelector("#favorites_countries");
let favoriteCountry: any;
if (favoritesCountryEl) {
	favoriteCountry = favoritesCountryEl.querySelector("script[type='application/json']");
	favoriteCountry = JSON.parse(favoriteCountry.textContent);
	sportType = favoritesCountryEl.dataset.sportType;
	const favoriteName = sportType === "tennis" || sportType === "table-tennis" ? t('header.categories') : t('header.country');

	createApp(Favorites)
		.provide("sportType", sportType)
		.provide("favoriteName", favoriteName)
		.provide("component", CountryFavorites)
		.provide("favoriteCountry", favoriteCountry)
		.use(pinia)
		.use(i18n)
		.mount(favoritesCountryEl);
}
// --RankingList
import RankingList from "./app/modules/ranking/RankingList.vue";
const rankingListEl: HTMLElement | null = document.querySelector("#rankings");

let rankingList: any;
if (rankingListEl) {
	rankingList = rankingListEl.querySelector("script[type='application/json']");
	rankingList = JSON.parse(rankingList.textContent);
	sportType = rankingListEl.dataset.sportType;

	createApp(Favorites)
		.provide("sportType", sportType)
		.provide("favoriteName", t('header.ranking'))
		.provide("component", RankingList)
		.provide("rankingList", rankingList)
		.use(pinia)
		.use(i18n)
		.mount(rankingListEl);
}
// --CurrentTournaments
import CurrentTournaments from "./app/modules/current/CurrentTournaments.vue";
const currentTournamentsEl: HTMLElement | null = document.querySelector("#current_tournaments");

let currentTournaments: any;
if (currentTournamentsEl) {
	currentTournaments = currentTournamentsEl.querySelector("script[type='application/json']");
	currentTournaments = JSON.parse(currentTournaments.textContent);
	currentTournaments = currentTournaments.tournaments;
	sportType = currentTournamentsEl.dataset.sportType;

	createApp(Favorites)
		.provide("sportType", sportType)
		.provide("favoriteName", t('header.current_tournaments'))
		.provide("component", CurrentTournaments)
		.provide("currentTournaments", currentTournaments)
		.use(pinia)
		.use(i18n)
		.mount(currentTournamentsEl);
}

// Header
import Header from "./app/modules/header/Header.vue";
const headerEl: HTMLElement | null = document.querySelector("#header");
if (headerEl) {	
	let mainMenu: any = headerEl.querySelector("script[type='application/json']");
	mainMenu = JSON.parse(mainMenu?.textContent);

	let presentData: any = headerEl.querySelector("#presentData");
    presentData = JSON.parse(presentData?.textContent);

	const token = headerEl.dataset.token,
		userName = headerEl.dataset.userName;

	createApp(Header)
		.provide("mainMenu", mainMenu)
		.provide("sportType", sportType)
		.provide("startLeague", startLeague)
		.provide("favoriteCountry", favoriteCountry)
		.provide("token", token)
		.provide("userName", userName)
		.provide("presentData", presentData)
		.use(pinia)
		.use(i18n)
		.mount(headerEl);
}

// GameDetailsPage
import PageGame from "./app/pages/PageGame.vue";
import createGameRouter from "@app/modules/game/gameRouter";

console.log(locale)
const gameDetailsEl: HTMLElement | null = document.querySelector("#game-details");
if (gameDetailsEl) {
	const api = gameDetailsEl.dataset.api;
	const sportType = gameDetailsEl.dataset.sportType;
	const matches = typeof api === "string" ? api.match(/(\d+)\/$/) : null;
	const isSlug = matches ? matches[1] : null;
	const slug = gameDetailsEl.dataset.slug || isSlug;
	const isLeague = typeof api === "string" && /\/sport\/league\//.test(api);
	const gameRouter = isLeague ? createGameRouter(api) : createGameRouter(`${locale.codeLang !== "ru" ? "/" + locale.codeLang: ''}/sport/info/${slug}/`);
	let gameInfo: any = gameDetailsEl.querySelector("script[type='application/json']");
	gameInfo = gameInfo ? JSON.parse(gameInfo.textContent) : null;

	createApp(PageGame)
		.provide("api", api)
		.provide("sportType", sportType)
		.provide("gameInfo", gameInfo)
		.use(pinia)
		.use(i18n)
		.use(FloatingVue, {
			disposeTimeout: 0,
			themes: {
				"history-tooltip": {
					$extend: "tooltip",
				},
			},
		})
		.use(gameRouter)
		.use(createHead())
		.mount(gameDetailsEl);
}

// Page Tournament
import PageTournament from "./app/pages/PageTournament.vue";
import createTournamentRouter from "@app/modules/tournament/tournamentRouter";

const tournamentEl: HTMLElement | null = document.querySelector("#tournament");
if (tournamentEl) {
	let head: any = tournamentEl.querySelector("script[type='application/json']");
	head = JSON.parse(head.textContent);

	const tournamentRouter = createTournamentRouter( (locale.codeLang !== "ru" ? "/" + locale.codeLang: '') + (tournamentEl.dataset.api ?? ""));
	console.log(tournamentEl.dataset.api)

	// ${locale.codeLang !== "ru" ? "/" + locale.codeLang: ''}

	createApp(PageTournament)
		.provide("head", head)
		.provide("sport", tournamentEl.dataset.sportType)
		.provide("api", tournamentEl.dataset.api)
		.use(pinia)
		.use(i18n)
		.use(FloatingVue, {
			disposeTimeout: 0,
			themes: {
				"history-tooltip": {
					$extend: "tooltip",
				},
			},
		})
		.use(tournamentRouter)
		.use(createHead())
		.mount(tournamentEl);
}

// Page Team
import PageTeam from "./app/pages/PageTeam.vue";
import createTeamRouter from "@app/modules/team/teamRouter";

const teamEl: HTMLElement | null = document.querySelector("#team-details");
if (teamEl) {
	let teamInfo: any = teamEl.querySelector("script[type='application/json']");
	teamInfo = JSON.parse(teamInfo.textContent);

	const api = teamEl.dataset.api,
		sportType = teamEl.dataset.sportType,
		teamRouter = createTeamRouter(`${locale.codeLang !== "ru" ? "/" + locale.codeLang: ''}/team/${teamInfo.slug}/`);

	createApp(PageTeam)
		.provide("api", api)
		.provide("sportType", sportType)
		.provide("teamInfo", teamInfo)
		.use(pinia)
		.use(i18n)
		.use(FloatingVue, {
			disposeTimeout: 0,
			themes: {
				"history-tooltip": {
					$extend: "tooltip",
				},
			},
		})
		.use(teamRouter)
		.mount(teamEl);
}

// Page Ranking
import Ranking from "./app/modules/ranking/Ranking.vue";
const rankingEl: HTMLElement | null = document.querySelector("#ranking");

if (rankingEl) {
	const api = rankingEl.getAttribute("data-api") || "";
	const sportType = rankingEl.dataset.sportType;
	
	createApp(Ranking)
		.provide("sportType", sportType)
		.provide("api", api)
		.use(pinia)
		.use(i18n)
		.mount(rankingEl);
}

// Page Player
import PagePlayer from "./app/pages/PagePlayer.vue";
import createPlayerRouter from "@app/modules/player/playerRouter";

const PagePlayerEl: HTMLElement | null = document.querySelector("#player-details");
if (PagePlayerEl) {
	let playerInfo: any = PagePlayerEl.querySelector("script[type='application/json']");
	playerInfo = JSON.parse(playerInfo.textContent);

	console.log(playerInfo);

	const playerRouter = createPlayerRouter(`${locale.codeLang !== "ru" ? "/" + locale.codeLang: ''}/player/${playerInfo.slug}/`),
		api = PagePlayerEl.dataset.api,
		sportType = PagePlayerEl.dataset.sportType;

	createApp(PagePlayer)
		.provide("api", api)
		.provide("sportType", sportType)
		.provide("playerInfo", playerInfo)
        .provide("favoriteCountry", favoriteCountry)
		.use(pinia)
		.use(i18n)
		.use(FloatingVue, {
			disposeTimeout: 0,
			themes: {
				"history-tooltip": {
					$extend: "tooltip",
				},
			},
		})
		.use(playerRouter)
		.mount(PagePlayerEl);
}

// Sport
import Sport from "./app/modules/sport/Sport.vue";

interface ComponentsList {
	app: any;
	target: HTMLElement | null;
	seoTitle: any | null;
}
const componentsList: ComponentsList[] = [
	{
		app: Sport,
		target: document.querySelector("#football"),
		seoTitle: t("seoTitle"),
	},
	{
		app: Sport,
		target: document.querySelector("#hockey"),
		seoTitle: t("seoTitle"),
	},
	{
		app: Sport,
		target: document.querySelector("#mma-ufc"),
		seoTitle: t("seoTitle"),
	},
	{
		app: Sport,
		target: document.querySelector("#boxing"),
		seoTitle: t("seoTitle"),
	},
	{
		app: Sport,
		target: document.querySelector("#tennis"),
		seoTitle: t("seoTitle"),
	},
	{
		app: Sport,
		target: document.querySelector("#basketball"),
		seoTitle: t("seoTitle"),
	},
	{
		app: Sport,
		target: document.querySelector("#table-tennis"),
		seoTitle: t("seoTitle"),
	},
];

const initComponents = (app: any, target: HTMLElement | null): void => {
	if (target) {
		createApp(app)
			.provide("sportType", target.dataset.sportType)
			.provide("api", target.dataset.api)
			.provide("seoTitle", t("seoTitle"))
			.use(pinia)
			.use(i18n)
			.use(FloatingVue, {
				disposeTimeout: 0,
				themes: {
					"coef-tooltip": {
						$extend: "tooltip",
					},
				},
			})
			.mount(target);
	}
};
for (const item of componentsList) initComponents(item.app, item.target);

// initForm
import FormContact from "./app/modules/form/FormContact.vue";
import FormAdvertising from "./app/modules/form/FormAdvertising.vue";
import {currentLocale} from "@app/helpers";

interface FormList {
	app: any;
	target: HTMLElement | null;
}
const formList: FormList[] = [
	{
		app: FormContact,
		target: document.querySelector("#contact-form"),
	},
	{
		app: FormAdvertising,
		target: document.querySelector("#advertising-form"),
	},
];

const initForm = (app: any, target: HTMLElement | null) => {
	if (target) {
		let formOptions: any = target.querySelector('script[type="application/json"]');
		formOptions = JSON.parse(formOptions.textContent);

		const formApp = createApp(app);
		formApp.provide("formOptions", formOptions);
		formApp.mount(target);
	}
};

for (const item of formList) initForm(item.app, item.target);
