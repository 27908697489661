<script setup lang="ts">
/*TODO:
 *-[x] Добавить STANDING в app
 *-[] Скелетоны
 */
import { createApp } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onUnmounted, computed, watch } from "vue";
import { useHead } from "@unhead/vue";
import { getApi } from "@app/helpers.ts";
import { GET_INTERVAL_DURATION } from "@app/consts.ts";
import CountryLeague from "@app/ui/country-league/CountryLeague.vue";
import DetailsGameSkeleton from "@app/modules/details/details-game/components/DetailsGameSkeleton.vue";
import { inject, ref } from "vue";
import EventIcon from "../details/details-game/ui/EventIcon.vue";

import type { GameResponse } from "./GameInterface";
import { useGame } from "./GameStore";
import { ROUTE_NAME_GAME, LIMITATION } from "./gameConsts";
import GameHead from "./components/game-head/GameHead.vue";
import GameHeadEvents from "./components/game-head/GameHeadEvents.vue";
import GameBookie from "./components/game-head/GameHeadBookie.vue";
import GameHeadSkeleton from "./components/GameHeadSkeleton.vue";
import DetailsGame from "../details/details-game/DetailsGame.vue";
import DetailsCoef from "../details/details-coef/DetailsCoef.vue";
import { useDetailsGame } from "../details/details-game/DetailsGameStore.js";
import usei18n from "@app/i18n/i18n";

import * as Sentry from "@sentry/vue";

const { t } = usei18n.global;

const storeDetailsGame = useDetailsGame(),
	storeGame = useGame();

const canonicalLink = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;

const gameInfo = inject<{
	title: string;
} | null>("gameInfo", null);

const props = defineProps<{
	value: {
		event_url: string;
		sport: string;
	};
}>();

const app = createApp({});

// router
const route = useRoute(),
	router = useRouter();

const isOpen = ref(false);

watch(
	() => route.fullPath,
	() => {
		if (canonicalLink) {
			canonicalLink.href = window.location.href;
		}
	}
);

Sentry.init({
	app,
	dsn: "https://e8507ac15b79497b9e48c952a0c81b24@sentry.raketa.agency/21",
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Integrations.Breadcrumbs({ console: false }),
		new Sentry.Replay(),
	],
});

Sentry.configureScope((scope) => {
	scope.setTag("frontend", "ts/vue");
});

app.use(router);
app.mount("#app");

// footer text
const templateTextEl: HTMLElement | null = document.querySelector("#template-text");

const activeNavItem = computed(() => {
	if (route.matched.length) {
		return storeGame?.game?.tabs?.[String(route.matched?.[0].name)];
	}

	return null;
});
const routeName = computed(() => {
	if (!route.name) {
		if (storeGame?.game?.tabs[ROUTE_NAME_GAME.MATCH]) {
			return "match";
		}
		return "coefficient";
	}
	return String(route.name);
});
// store
const getGame = (): void => {
	getApi<GameResponse>(props.value.event_url + "menu/").then((response) => {
		console.log(response);
		if (response) {
			storeGame.game = response;

			if (storeGame.game?.tabs) {
				if (storeGame.game.tabs[ROUTE_NAME_GAME.BROADCAST]) {
					storeGame.game.tabs[ROUTE_NAME_GAME.BROADCAST] = {
						name: t("game.broadcast"),
					};
				}
				if (route.path === "/") {
					const currentName = storeGame.game.tabs[ROUTE_NAME_GAME.MATCH]
						? ROUTE_NAME_GAME.MATCH
						: ROUTE_NAME_GAME.COEFFICIENT;
					router.addRoute({ path: "/", redirect: `/${currentName}/` });
					if (storeGame.game.tabs[ROUTE_NAME_GAME.MATCH]) {
						console.log(storeGame.game.tabs[ROUTE_NAME_GAME.MATCH]);
					}
					// router.push({ name: currentName });
				}
				const limitation = LIMITATION[props.value.sport as keyof typeof LIMITATION];
				if (limitation) {
					for (const limTabs of limitation) {
						storeGame.game.tabs = Object.fromEntries(
							Object.entries(storeGame.game.tabs).filter(([key]) => key !== limTabs)
						);
					}
				}
				console.log(storeGame.game.tabs);
				// footer team link
				if (templateTextEl) {
					const paragraphElement = templateTextEl.querySelector("p");
					if (
						paragraphElement &&
						storeGame.game?.team_away?.[0].route &&
						storeGame.game?.team_home?.[0].route
					) {
						const originalText = paragraphElement.textContent || "";
						const modifiedText = originalText
							.replace(
								new RegExp(`${storeGame.game?.team_away?.[0].name}`),
								`<a href="${storeGame.game?.team_away?.[0].route}">${storeGame.game?.team_away?.[0].name}</a>`
							)
							.replace(
								new RegExp(`${storeGame.game?.team_home?.[0].name}`),
								`<a href="${storeGame.game?.team_home?.[0].route}">${storeGame.game?.team_home?.[0].name}</a>`
							);
						paragraphElement.innerHTML = modifiedText;
					}
				}
			}
			storeGame.isReady = true;
			useHead({
				title: (): any => {
					if (routeName.value && route.path !== "/") {
						return storeGame.game?.tags?.[routeName.value]?.title;
					} else if (routeName.value && route.path === "/") {
						return storeGame.game?.tags?.main?.title;
					}
				},
				meta: [
					{
						name: "description",
						content: (): any => {
							if (routeName.value && route.path !== "/") {
								return storeGame.game?.tags?.[routeName.value]?.description;
							} else if (routeName.value && route.path === "/") {
								return storeGame.game?.tags?.main?.description;
							}
						},
					},
					{
						property: "og:title",
						content: (): any => {
							if (routeName.value && route.path !== "/") {
								return storeGame.game?.tags?.[routeName.value]?.title;
							} else if (routeName.value && route.path === "/") {
								return storeGame.game?.tags?.main?.title;
							}
						},
					},
					{
						property: "og:description",
						content: (): any => {
							if (routeName.value && route.path !== "/") {
								return storeGame.game?.tags?.[routeName.value]?.description;
							} else if (routeName.value && route.path === "/") {
								return storeGame.game?.tags?.main?.description;
							}
						},
					},
				],
			});
		}
	});
};

const getGameInfo = (): void => {
	getApi<GameResponse>(props.value.event_url + "match/summary/").then((response) => {
		if (response) {
			storeDetailsGame.createCompositions(response);
		}
	});
};

getGame();
getGameInfo();

// fetch Interval
const getGameInterval = setInterval(() => {
	if (storeGame.game?.status !== t('game_head.status.over') && !document.hidden) {
		getGame();
	} else if (storeGame.game?.status === t('game_head.status.over')) {
		clearInterval(getGameInterval);
	}
}, GET_INTERVAL_DURATION);

document.addEventListener("visibilitychange", () => {
	if (storeGame.game?.status !== t('game_head.status.over') && !document.hidden) {
		getGame();
	}
});

onUnmounted(() => {
	clearInterval(getGameInterval);
});

const tabOrder = (tabKey: string | number): number => {
	switch (tabKey) {
		case ROUTE_NAME_GAME.MATCH:
			return 1;

		case ROUTE_NAME_GAME.COEFFICIENT:
			return 2;

		case ROUTE_NAME_GAME.BROADCAST:
			return 7;

		default:
			return 3;
	}
};

// smallH1
const title = computed(() => {
	if (route.name) return storeGame.game?.tags?.[String(route.name)]?.h1;
});

function handleButtonClick(key: string) {
	if (key === "broadcast") {
		// @ts-ignore
		ym(91864289, "reachGoal", "click_video");
		return true;
	}
}

const isActive = (key: string) => {
	if (route.path === "/" && storeGame.game?.tabs[ROUTE_NAME_GAME.MATCH] && key === "match") {
		return "_active";
	} else if (route.path === "/" && !storeGame.game?.tabs[ROUTE_NAME_GAME.MATCH] && key === "coefficient") {
		return "_active";
	}
	if (templateTextEl && route.path !== "/") {
		templateTextEl.style.display = "none";
	}
};
</script>

<template>
	<div v-if="storeGame.isReady && storeGame.game" class="game-details">
		<h1 v-if="gameInfo?.title" class="smallH1">
			{{ title || gameInfo.title }}
		</h1>
		<div class="league">
			<CountryLeague
				:country-code="storeGame.game.country.code"
				:country-name="storeGame.game.country.country_name"
				:league-name="storeGame.game.country.league_name"
				:league-id="storeGame.game.country.league_id"
				:country-slug="storeGame.game.country.countrySlug"
				:league-slug="storeGame.game.country.leagueSlug"
			/>
		</div>

		<GameHead
			:sport="value.sport"
			:status="storeGame.game.status"
			:start-time="storeGame.game.start_time"
			:clock="storeGame.game.clock"
			:score="storeGame.game.score"
			:penalty-score="storeGame.game.penalty_score"
			:overtime-score="storeGame.game.overtime_score"
			:team-home="storeGame.game.team_home"
			:team-away="storeGame.game.team_away"
		/>
		<GameBookie :api="storeGame.game.tabs.coefficient.api || ''" />
		<div
			v-if="
				storeDetailsGame.compositions.mainReferee ||
				storeDetailsGame.compositions?.venue?.name ||
				storeDetailsGame.compositions?.venue?.capacity
			"
		>
			<button @click="isOpen = !isOpen" :class="{ _active: isOpen }" class="open-wrap">
				<span class="open-wrap__text">{{ $t("game.info") }}</span>
				<div class="open-wrap__icon">
					<svg width="20" height="20">
						<use xlink:href="#20-arrow" />
					</svg>
				</div>
			</button>
			<div v-if="isOpen" class="wrap">
				<ul class="list">
					<li v-if="storeDetailsGame.compositions.mainReferee" class="list__item">
						<div class="list__icon">
							<EventIcon :name="'20-whistle'" />
						</div>
						<span class="list__info">{{ $t("details_game.referee") }}</span>
						<span class="list__info-name">{{ storeDetailsGame.compositions.mainReferee }}</span>
					</li>
					<li v-if="storeDetailsGame.compositions?.venue?.name" class="list__item">
						<div class="list__icon">
							<EventIcon :name="'20-stadium'" />
						</div>
						<span class="list__info">{{ $t("details_game.stadium") }}</span>
						<span class="list__info-name">{{ storeDetailsGame.compositions.venue.name }}</span>
					</li>
					<li v-if="storeDetailsGame.compositions?.venue?.capacity" class="list__item">
						<div class="list__icon">
							<EventIcon :name="'20-people'" />
						</div>
						<span class="list__info">{{ $t("details_game.attendance") }}</span>
						<span class="list__info-name">{{ storeDetailsGame.compositions.venue.capacity }}</span>
					</li>
				</ul>
			</div>
		</div>

		<div class="tabs-wrap">
			<ul class="tabs">
				<li
					v-if="storeGame.game.tabs"
					v-for="(item, key) in storeGame.game.tabs"
					:key="'game-details_nav' + key"
					:style="{ order: tabOrder(key) }"
					class="tab__item"
					:class="{ 'broadcast-tab': key === 'broadcast' }"
				>
					<router-link
						:to="`/${key}/`"
						class="tab-btn"
						:class="isActive(key)"
						@click="handleButtonClick(key)"
					>
						{{ item.name }}
						<svg v-if="key === 'broadcast'" width="25" height="25">
							<use xlink:href="#16-broadcast" />
						</svg>
					</router-link>
				</li>
			</ul>
		</div>
		<p v-if="!storeGame.game?.tabs && route.matched?.[0].components?.default">Данных нет</p>
		<DetailsGame
			v-else-if="route.path === '/' && storeGame.game.tabs[ROUTE_NAME_GAME.MATCH]"
			:api="storeGame.game.tabs.match.api || ''"
		/>
		<DetailsCoef
			v-else-if="route.path === '/' && !storeGame.game.tabs[ROUTE_NAME_GAME.MATCH]"
			:api="storeGame.game.tabs.coefficient.api || ''"
		/>
		<router-view v-else :api="activeNavItem?.api" />

		<GameHeadEvents
			:id="storeGame.game.id ? Number(storeGame.game.id) : undefined"
			:teams="storeGame.game.teams ?? undefined"
			:title="t('game.events')"
		/>
	</div>

	<div v-else>
		<GameHeadSkeleton />
		<DetailsGameSkeleton />
	</div>
</template>

<style scoped lang="scss">
@import "@assets/scss/utils.scss";

.tab-btn svg {
	margin-left: 5px;
	margin-top: -5px;
	stroke: var(--color-dop-text);
}
.tab-btn.router-link-active svg {
	stroke: hsl(220, 4%, 14%);
}

.broadcast-tab {
	stroke: hsl(220, 4%, 14%);
	margin-left: auto;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: -11px;
		left: -10px;
		margin: 4px;
		-webkit-animation-name: replay;
		animation-name: replay;
		-webkit-animation-duration: 1.7s;
		animation-duration: 1.7s;
		-webkit-animation-timing-function: ease;
		animation-timing-function: ease;
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		-webkit-animation-direction: normal;
		animation-direction: normal;
		-webkit-animation-fill-mode: none;
		animation-fill-mode: none;
		-webkit-animation-play-state: running;
		animation-play-state: running;
		height: 50px;
		width: 198px;
		border-radius: 8px;
	}
}

@keyframes replay {
	100% {
		padding: 8px;
		box-shadow: inset 0 0 10px 10px rgb(67, 176, 9, 0);
		border-radius: 8px;
	}
	0% {
		box-shadow: inset 0 0 30px 30px rgba(67, 176, 9, 0.3);
		border-radius: 8px;
	}
}

.league {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	padding-right: 28px;
}

.wrap-video {
	width: 100%;
	background: var(--color-dop-back);
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 20px;
}

.open-wrap__text {
	font-size: 14px;
	line-height: 100%;
	text-align: right;
	color: var(--color-dop-text);
}

.open-wrap__number {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 4px;
	min-height: 20px;
	min-width: 20px;
	border-radius: 6px;
	background: var(--color-main-back);
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: var(--color-main-text);
	text-align: center;
}

.open-wrap__icon {
	position: relative;
	width: 20px;
	height: 40px;

	> svg {
		@include center();
		width: 24px;
		height: auto;
		stroke: var(--color-dop-cta);
		stroke-width: 1.5;
		stroke-linecap: round;
		stroke-linejoin: round;
	}
}

.open-wrap {
	margin-left: 5px;
	margin-bottom: 10px;
	&._active {
		.open-wrap__icon {
			> svg {
				transform: translate(-50%, -50%) rotate(180deg);
			}
		}
	}
}

@media screen and (max-width: 1170px) {
	.tab__item:last-child {
		margin-left: inherit;
	}
}

.wrap {
	display: flex;
	flex-direction: column;
	gap: 6px;
	margin-bottom: 20px;
}

.infohead {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
	padding: 2px 2px 2px 6px;
	background: var(--color-dop-cta);
	border-radius: 6px;
}
.infohead__name {
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	color: hsl(220, 4%, 14%);
	margin: 0;
}
.list {
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.list__item {
	display: flex;
	align-items: center;
	gap: 6px 12px;
	flex-wrap: wrap;
	padding: 0px 6px 4px;
	background: var(--color-border);
	background-size: 100% 1px;
	background-repeat: no-repeat;
	background-position: bottom left;

	&._away {
		flex-direction: row-reverse;
	}

	&:last-child {
		background: none;
	}
}
.list__name-primary {
	color: var(--color-main-text);
}
.list__name-secondary {
	color: var(--color-dop-text);
}

.list__info,
.list__info-name {
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
}

.list__info-name {
	margin-left: auto;
}
</style>
